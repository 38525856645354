// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";

// Components
import Header from "./desktop-version/components/header";
import HistoryPrice from "./desktop-version/components/HistoryPrice";
import LoaderBlue from "./desktop-version/components/LoaderBlue";
import Maps from "./desktop-version/components/Maps";
import ProductCard from "./desktop-version/components/ProductCard";
import PricesByZone from "./desktop-version/components/PricesByZone";
import SearchBar from "./desktop-version/components/SearchBar";
import SelectCity from "./desktop-version/components/SelectCity";

import HeaderMobile from "./mobile-version/components/header-mobile";
import HistoryPriceMobile from "./mobile-version/components/HistoryPriceMobile";
import LoaderMobile from "./mobile-version/components/LoaderMobile";
import MapMobile from "./mobile-version/components/Maps-mobile";
import PricesByZoneMobile from "./mobile-version/components/PriceByZoneMobile";
import SelectCityMobile from "./mobile-version/components/SelectCityMobile";

// Utils
import moment from "moment";
import { postPOS } from "../../utils/axios";
import Segment from "../../utils/segment";
import useWindowSize from "../../utils/useWindowSize";

// Styles
import styles from "./bulletin.module.css";

function Bulletin() {
  const [width] = useWindowSize();
  const [citySelect, setCitySelect] = useState(true);
  const [cityId, setCityId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [top20Skus, setTop20Skus] = useState([]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [dataGeneral, setDataGeneral] = useState(null);
  const [data, setData] = useState(null);
  const [dataHistoryPrice, setDataHistoryPrice] = useState([]);
  const [average, setAverage] = useState(null);
  const [dataReference, setDataReference] = useState(null);
  const [topTurnover, setTopTurnover] = useState(null);
  const [storesWithReferences, setStoresWithReferences] = useState(null);
  const [index, setIndex] = useState(0);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [averagePrice, setAveragePrice] = useState(null);
  const [dataGeneralLength, setDataGeneralLength] = useState(null);
  const [direction, setDirection] = useState("LEFT");

  const selectCityId = (cityId) => {
    setCityId(cityId);
    setCitySelect(false);
  };

  useEffect(() => {
    cityId && storesByCity(cityId);
    Segment.trackEvent({
      event: "Bulletin_Landing_Click",
    });
    // eslint-disable-next-line
  }, [cityId]);

  const storesByCity = async (cityId) => {
    setLoading(true);
    const dateNow = moment.utc().format();
    await postPOS("best-selling-products-by-city", { dateNow, cityId })
      .then((resp) => {
        setDataGeneral(resp.data.references);
        setDataGeneralLength(resp.data.references.length);
        setTop20Skus(resp.data.topTenSkus);
        setLat(parseFloat(resp.data.lat));
        setLng(parseFloat(resp.data.lng));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        console.error(err);
      });
  };

  useEffect(() => {
    dataGeneral &&
      dataGeneral.length > 0 &&
      index < top20Skus.length &&
      top20Skus.push("posImage") &&
      dataGeneral.push({
        posImage: {
          reference: {
            imageUrl:
              "https://d221xxk5mfaxk5.cloudfront.net/app/pos-pantalla-cliente-3-SJ2R8cr7u-M.png",
          },
          stores: [],
        },
      }) &&
      bestSellingProductbySKU(dataGeneral);
    // eslint-disable-next-line
  }, [dataGeneral, index, top20Skus, lat, lng]);

  const bestSellingProductbySKU = async (data) => {
    const propSku = top20Skus[index];
    setData(data[index][propSku].stores);
    setTopTurnover(
      !!data[index][propSku].stores &&
        data[index][propSku].stores.filter((store) => {
          return !!store.topTurnover && store.customerTotal;
        })
    );
    let customerTotals = [];
    const averages =
      !!data[index][propSku].stores.length &&
      data[index][propSku].stores.map((ref) => {
        customerTotals.push(parseFloat(ref.customerTotal));
        return parseFloat(ref.customerTotal) * parseFloat(ref.quantity);
      });
    customerTotals.sort((a, b) => a - b);
    setMinPrice(customerTotals[0]);
    setMaxPrice(customerTotals[customerTotals.length - 1]);
    setAveragePrice(
      !!customerTotals.length &&
        customerTotals.reduce((a, b) => a + b) / customerTotals.length
    );
    const average =
      !!averages.length &&
      averages.reduce((a, b) => a + b) / data[index][propSku].stores.length;
    setAverage(average);
    setDataReference(data[index][propSku].reference);
    setDataHistoryPrice(
      Number(index) === dataGeneralLength
        ? data[0][top20Skus[0]].monthYear
        : data[index][propSku].historyPrice
    );
    setStoresWithReferences(data[index][propSku].stores.length);
  };

  const increaseSku = (index) => {
    setDirection("LEFT");
    const i = index === dataGeneralLength ? index : index + 1;
    setIndex(i);
  };

  const decreaseSku = (index) => {
    setDirection("RIGHT");
    const i = index === 0 ? index : index - 1;
    setIndex(i);
  };

  return (
    <Fragment>
      {width >= 769 ? (
        <div className={styles.body}>
          <Header cityId={cityId} />
          {loading && <LoaderBlue />}
          {!citySelect ? (
            !error ? (
              <div className={styles.generalContainer}>
                <SearchBar />
                <div className={styles.containerCards}>
                  {!!lat && !!lng && !!data && (
                    <div className={styles.gridBody}>
                      <PricesByZone
                        topTurnover={topTurnover}
                        averagePrice={averagePrice}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        cityId={cityId}
                      />
                      <div className={styles.gridBodyMap}>
                        <Maps lng={lng} lat={lat} data={data} cityId={cityId} />
                      </div>
                    </div>
                  )}
                  {!!dataReference && (
                    <ProductCard
                      topTurnover={
                        !!topTurnover && !!topTurnover[0]
                          ? topTurnover[0].customerTotal
                          : null
                      }
                      dataGeneralLength={dataGeneralLength}
                      dataReference={dataReference}
                      top20Skus={top20Skus}
                      increaseSku={increaseSku}
                      decreaseSku={decreaseSku}
                      index={index}
                      cityId={cityId}
                    />
                  )}
                  {!!dataReference && (
                    <HistoryPrice
                      quantity={!!dataReference && dataReference.quantity}
                      dataHistoryPrice={dataHistoryPrice}
                      storesWithReferences={storesWithReferences}
                      average={average}
                      cityId={cityId}
                    />
                  )}
                </div>
              </div>
            ) : (
              <h4 className={styles.noData}>
                Ha ocurrido un error. Por favor inténtalo más tarde
              </h4>
            )
          ) : (
            <SelectCity selectCityId={selectCityId} />
          )}
        </div>
      ) : (
        <div className={styles.bodyMobile}>
          <HeaderMobile cityId={cityId} />
          {loading && <LoaderMobile />}
          {!citySelect ? (
            !error ? (
              <div className={styles.generalContainerMobile}>
                <div className={styles.containerCardsMobile}>
                  {!!lat && !!lng && !!data && (
                    <div className={styles.gridBodyMobile}>
                      <PricesByZoneMobile
                        topTurnover={topTurnover}
                        dataGeneralLength={dataGeneralLength}
                        index={index}
                        averagePrice={averagePrice}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        cityId={cityId}
                      />

                      <MapMobile
                        dataReference={dataReference}
                        lng={lng}
                        lat={lat}
                        data={data}
                        topTurnover={
                          !!topTurnover && !!topTurnover[0]
                            ? topTurnover[0].customerTotal
                            : null
                        }
                        top20Skus={top20Skus}
                        increaseSku={increaseSku}
                        decreaseSku={decreaseSku}
                        index={index}
                        dataGeneralLength={dataGeneralLength}
                        cityId={cityId}
                        direction={direction}
                      />

                      {!!dataReference && (
                        <HistoryPriceMobile
                          quantity={!!dataReference && dataReference.quantity}
                          cityId={cityId}
                          storesWithReferences={storesWithReferences}
                          average={average}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <h4 className={styles.noData}>
                Ha ocurrido un error. Por favor inténtalo más tarde
              </h4>
            )
          ) : (
            <SelectCityMobile selectCityId={selectCityId} />
          )}
        </div>
      )}
    </Fragment>
  );
}

export default withRouter(Bulletin);
