import React, { Fragment } from "react";
import i18n from "./i18n";
import moment from "moment-timezone";

const formatCurrency = (input, cityId) => {
  if (Number(cityId) === 11 || Number(cityId) === 19) {
    i18n.language = "esMX";
  } else {
    i18n.language = "esCO";
  }
  if (!input) return 0;

  const value = input
    .toFixed(
      i18n.store.data[i18n.language]["global"]["currencyFormat"]["precision"]
    )
    .replace(
      ".",
      i18n.store.data[i18n.language]["global"]["currencyFormat"]["delimiter"]
    );
  const splitedValue = value.split(
    i18n.store.data[i18n.language]["global"]["currencyFormat"]["separator"]
  );
  const integer = `${
    i18n.store.data[i18n.language]["global"]["currencyFormat"]["unit"]
  }${splitedValue[0]}`.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${
      i18n.store.data[i18n.language]["global"]["currencyFormat"]["separator"]
    }`
  );
  const decimal = !!splitedValue[1]
    ? `${
        i18n.store.data[i18n.language]["global"]["currencyFormat"]["delimiter"]
      }${splitedValue[1]}`
    : "";

  return `${integer}${decimal}`.replace(/\s/g, "");
};

const formatSku = (data) => {
  if (data) {
    const last = data.slice(data.length - 4);
    data = data.slice(0, -4);
    return (
      <Fragment>
        {data}
        {last}
      </Fragment>
    );
  } else {
    return "";
  }
};

const formatData = (data, type, cityId) => {
  if (type === "date") {
    let timezone = "America/Bogota";
    data = moment(data)
      .tz(timezone)
      .format("ddd DD[,] MMM YYYY")
      .replace(".", "");
  } else if (type === "currency") {
    data = formatCurrency(data, cityId);
  } else if (type === "sku") {
    data = formatSku(data);
  } else if (type === "onlyNumbers") {
    const reg = new RegExp("^\\d+$");
    return reg.test(data);
  }
  return data;
};

export default formatData;
