import React from "react";
import Navigation from "./modules/navigation/index";
import "./assets/theme/fonts.css";
import "./App.css";

function App() {
  return <Navigation />;
}

export default App;
