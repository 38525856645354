const _device = () => {
  try {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile ? "Mobile" : "Desktop";
  } catch (e) {
    console.error("error in search device:", e);
    return "Desktop";
  }
};

const _environment = () => {
  return process.env.REACT_APP_ENVIRONMENT;
};

const _city = () => {
  try {
    const user = "";
    if (!!user) {
      return user.city;
    } else {
      return "no match";
    }
  } catch (e) {
    console.log("Error in City:", e);
    return "co";
  }
};

const _country = () => {
  try {
    const domain = window.location.hostname;
    const split = domain.split(".");
    return split.includes("localhost") || split.includes("ngrok")
      ? process.env.REACT_APP_COUNTRY_TEST
      : split[split.length - 1];
  } catch (e) {
    console.log("Error in Country:", e);
    return "co";
  }
};

const App = "Empresarios-web";

const isRequired = () => {
  throw new Error("param is required");
};
// Exportables
/* example:
  import segment from './utils/segment'
  segment.onTrack('test', 'testing', 'onClick')
 */
const onTrack = (
  name = isRequired(),
  type = isRequired(),
  event = isRequired(),
  time = 0,
  data
) => {
  let sendData = {};
  if (type && name && event) {
    if (
      event === "onClick" ||
      event === "backLink" ||
      event === "onScroll" ||
      event === "onRender"
    ) {
      sendData = {
        environment: _environment(),
        country: _country(),
        device: _device(),
        city: _city(),
        type: type,
        name: name,
        // storeId: getStoreId(),
        deviceSize: `${window.innerWidth}x${window.innerHeight}`,
        App,
        ...data,
      };
    }

    if (event === "onScroll") {
      sendData = {
        ...sendData,
        time: time,
      };
    }
    try {
      window.analytics.track(event, sendData);
    } catch (e) {
      console.error(`Error Segment ${event}:`, e);
    }
  } else {
    console.error("Error Segment miss data");
  }
};
const Indetify = (userId, name, email) => {
  try {
    window.analytics.identify(userId, {
      name: name,
      email: email,
    });
  } catch (e) {
    console.error("Error Segment init:", e);
  }
};

const generateUuid = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15) +
    "-" +
    Date.now()
  );
};

const _id = () => {
  if (localStorage.getItem("uniqSessionId")) {
    const item = JSON.parse(localStorage.getItem("uniqSessionId")).id;
    return item;
  } else {
    const uniqId = generateUuid();
    localStorage.setItem("uniqSessionId", JSON.stringify({ id: uniqId }));
    return uniqId;
  }
};

const getSessionId = () => {
  const jsonId = _id();
  if (jsonId) {
    return jsonId;
  } else {
    const uniqId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      "-" +
      Date.now();
    localStorage.setItem("uniqSessionId", JSON.stringify({ id: uniqId }));
    return uniqId;
  }
};

const trackEvent = ({ event, data }) => {
  if (event) {
    try {
      const dataToSend = {
        deviceSize: `${window.innerWidth}x${window.innerHeight}`,
        App,
        // storeId: getStoreId(),
        country: _country(),
        device: _device(),
        city: _city(),
        sessionId: getSessionId(),
        environment: _environment(),
        ...data,
      };
      window.analytics.track(event, dataToSend);
    } catch (e) {
      console.error(`Error Segment ${event}:`, e);
    }
  }
};

export default {
  onTrack,
  Indetify,
  trackEvent,
};
