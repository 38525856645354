import React from "react";
import GoogleMapReact from "google-map-react";
import formatData from "../../../../../utils/formatData";
import ProductCardMobile from "../ProductCardMobile";
import POS from "../../../../../../assets/images/desktop/POS.svg";
import styles from "./mapsMobile.module.css";

const MapMobile = (props) => {
  const {
    cityId,
    data,
    dataGeneralLength,
    dataReference,
    decreaseSku,
    direction,
    increaseSku,
    index,
    lat,
    lng,
    top20Skus,
    topTurnover,
  } = props;

  const goForm = () => {
    window.open("https://growth.chiper.co/formulario-pos/", "_blank");
  };

  return (
    <div
      className={
        index < dataGeneralLength ? styles.gridBodyMap : styles.gridBodyMap2
      }
    >
      {index < dataGeneralLength ? (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
          }}
          center={{ lat, lng }}
          defaultZoom={11}
          options={{
            maxZoom: 16,
            minZoom: 10,
            fullscreenControl: false,
            mapTypeId: "roadmap",
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            gestureHandling: "cooperative",
          }}
          className={styles.map}
        >
          {!!dataReference && (
            <ProductCardMobile
              cityId={cityId}
              dataReference={dataReference}
              decreaseSku={decreaseSku}
              direction={direction}
              increaseSku={increaseSku}
              dataGeneralLength={dataGeneralLength}
              index={index}
              top20Skus={top20Skus}
              topTurnover={topTurnover}
            />
          )}
          {!!data &&
            data.map((item) =>
              !!item.customerTotal && parseFloat(item.customerTotal) > 0 ? (
                !!item.topTurnover ? (
                  <div
                    key={`${item.longitude}${item.latitude}}`}
                    lat={item.latitude}
                    lng={item.longitude}
                  >
                    <div className={styles.circleBlue}></div>
                    <div className={styles.popupBubble}>
                      {formatData(
                        parseFloat(item.customerTotal),
                        "currency",
                        cityId
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    key={`${item.longitude}${item.latitude}}`}
                    lat={item.latitude}
                    lng={item.longitude}
                  >
                    <div className={styles.circle}></div>
                    <div className={styles.popupBubbleOther}>
                      {formatData(
                        parseFloat(item.customerTotal),
                        "currency",
                        cityId
                      )}
                    </div>
                  </div>
                )
              ) : (
                <div></div>
              )
            )}
        </GoogleMapReact>
      ) : (
        <div className={styles.map2}>
          <button className={styles.goForm} onClick={goForm} type="button">
            Solicitar un POS
          </button>
          <img alt={POS} src={POS} className={styles.POS} />
          {!!dataReference && (
            <ProductCardMobile
              cityId={cityId}
              dataReference={dataReference}
              decreaseSku={decreaseSku}
              direction={direction}
              increaseSku={increaseSku}
              dataGeneralLength={dataGeneralLength}
              index={index}
              top20Skus={top20Skus}
              topTurnover={topTurnover}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MapMobile;
