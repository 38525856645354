import React from "react";
import formatData from "../../../../../utils/formatData";
//Styles
import styles from "../BulletinMobile/bulletinMobile.module.css";

function PricesByZoneMobile(props) {
  const {
    topTurnover,
    averagePrice,
    maxPrice,
    minPrice,
    cityId,
    index,
    dataGeneralLength,
  } = props;
  return (
    <div
      className={
        index < dataGeneralLength ? styles.gridBodySup : styles.gridBodySuper
      }
    >
      <div className={styles.textSup}> Precios por zona </div>
      {index < dataGeneralLength && (
        <div className={styles.pricesContainer}>
          <div className={styles.price}>
            <div className={styles.priceTextBlue}>Mayor rotación:</div>
            <div className={styles.priceText}>Promedio:</div>
          </div>
          <div className={styles.price}>
            <div className={styles.priceNumberBlue}>
              {!!topTurnover && !!topTurnover[0]
                ? formatData(
                    parseFloat(topTurnover[0].customerTotal),
                    "currency",
                    cityId
                  )
                : "N/A"}
            </div>
            <div className={styles.priceNumber}>
              {!!averagePrice
                ? formatData(parseFloat(averagePrice), "currency", cityId)
                : "N/A"}
            </div>
          </div>
          <div className={styles.price}>
            <div className={styles.priceText}>Más alto:</div>
            <div className={styles.priceText}>Más bajo:</div>
          </div>
          <div className={styles.price}>
            <div className={styles.priceNumber}>
              {!!maxPrice
                ? formatData(parseFloat(maxPrice), "currency", cityId)
                : "N/A"}
            </div>
            <div className={styles.priceNumber}>
              {!!minPrice
                ? formatData(parseFloat(minPrice), "currency", cityId)
                : "N/A"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PricesByZoneMobile;
