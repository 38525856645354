// React
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";

// Assets
import logoHeader from "./../../../../../../assets/images/desktop/chiper-pos.svg";
import logoTruck from "./../../../../../../assets/images/desktop/TruckMobile.svg";
import logoPaper from "./../../../../../../assets/images/desktop/Form.svg";

//Styles
import styles from "./headerMobile.module.css";

const HeaderMobile = (props) => {
  const { cityId } = props;

  const goEcommerce = () => {
    if (Number(cityId) === 11 || Number(cityId) === 19) {
      window.open("https://chiper.mx/pedir/dashboard", "_blank");
    } else {
      window.open("https://chiper.co/pedir/dashboard", "_blank");
    }
  };

  const goForm = () => {
    window.open("https://growth.chiper.co/formulario-pos/", "_blank");
  };

  return (
    <Fragment>
      <header className={styles.container}>
        <img className={styles.logoHeader} src={logoHeader} alt={"Chiper"} />
        <div className={styles.containerLogos}>
          <div className={styles.circleBlack} onClick={goForm}>
            <img src={logoPaper} alt={"Solicita tu POS"} />
          </div>
          <div className={styles.circleBlack} onClick={goEcommerce}>
            <img src={logoTruck} alt={"Pide aqui"} />
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default withRouter(HeaderMobile);
