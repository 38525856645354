import React from "react";
import GoogleMapReact from "google-map-react";
import formatData from "../../../../../utils/formatData";

import styles from "./maps.module.css";

const Map = (props) => {
  const { lat, lng, data, cityId } = props;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      }}
      center={{ lat, lng }}
      defaultZoom={11}
      options={{
        maxZoom: 15,
        minZoom: 11,
        fullscreenControl: false,
        mapTypeId: "roadmap",
        zoomControl: true,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: "cooperative",
      }}
      className={styles.map}
    >
      {!!data &&
        !!data.length &&
        data.map((item, i) =>
          !!item.customerTotal ? (
            parseFloat(item.customerTotal) > 0 ? (
              !!item.topTurnover ? (
                <div
                  key={`${item.longitude}${item.latitude}${i}`}
                  lat={item.latitude}
                  lng={item.longitude}
                >
                  <div className={styles.circleBlue}></div>
                  <div className={styles.popupBubble}>
                    {formatData(
                      parseFloat(item.customerTotal),
                      "currency",
                      cityId
                    )}
                  </div>
                </div>
              ) : (
                <div
                  key={`${item.longitude}${item.latitude}${i}`}
                  lat={item.latitude}
                  lng={item.longitude}
                >
                  <div className={styles.circle}></div>
                  <div className={styles.popupBubbleOther}>
                    {formatData(
                      parseFloat(item.customerTotal),
                      "currency",
                      cityId
                    )}
                  </div>
                </div>
              )
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )
        )}
    </GoogleMapReact>
  );
};

export default Map;
