import React, { Fragment } from "react";
import Bulletin from "./Bulletin";
// import DesktopVersion from "./Bulletin/desktop-version";
// import MobileVersion from "./Bulletin/mobile-version";
// import useWindowSize from "../utils/useWindowSize";

function Home() {
  // const [width] = useWindowSize();
  return (
    <Fragment>
      <Bulletin />
    </Fragment>
  );
}
export default Home;
