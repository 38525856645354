import React from "react";
import { Transition, animated } from "react-spring/renderprops";
import { useSwipeable } from "react-swipeable";
import formatData from "../../../../../utils/formatData";

//Styles
import styles from "./productCardMobile.module.css";

function ProductCardMobile(props) {
  const {
    cityId,
    dataGeneralLength,
    dataReference,
    decreaseSku,
    direction,
    increaseSku,
    index,
    topTurnover,
  } = props;

  const goEcommerce = () => {
    if (Number(cityId) === 11 || Number(cityId) === 19) {
      window.open("https://chiper.mx/pedir/dashboard", "_blank");
    } else {
      window.open("https://chiper.co/pedir/dashboard", "_blank");
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => increaseSku(index),
    onSwipedRight: () => decreaseSku(index),
    preventDefaultTouchmoveEvent: true,
    // trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className={
        index < dataGeneralLength
          ? styles.containerProductCards
          : styles.containerProductCardsPOS
      }
    >
      <Transition
        delay={100}
        native
        reset
        unique
        items={index}
        from={
          direction === "RIGHT"
            ? { opacity: 0.8, transform: "translate3d(-250%,0,0)" }
            : { opacity: 0, transform: "translate3d(250%,0,0)" }
        }
        enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
        leave={
          direction === "RIGHT"
            ? { opacity: 0, transform: "translate3d(50%,0,0)" }
            : { opacity: 0, transform: "translate3d(-50,0,0)" }
        }
      >
        {(index) => (style) => (
          <animated.div
            style={{
              ...style,
              display: "flex",
            }}
          >
            {index < dataGeneralLength ? (
              <div className={styles.containerProductCard} key={index}>
                <div className={styles.containerImageCard}>
                  <img
                    src={
                      dataReference.imageUrl
                        ? dataReference.imageUrl
                        : "https://d221xxk5mfaxk5.cloudfront.net/app/image-not-found-HJDRw_QZI-M.png"
                    }
                    alt={dataReference.name}
                    className={styles.image}
                  ></img>
                </div>
                <div className={styles.containerInfo}>
                  <div className={styles.referenceTitle}>
                    {!!dataReference &&
                    dataReference.name &&
                    dataReference.name.length > 35
                      ? dataReference.name.slice(0, 35) + "..."
                      : dataReference.name}
                  </div>
                  <div className={styles.supText}>
                    Precio de mayor rotación:
                  </div>
                  <div className={styles.inputTopTurnoverPrice}>
                    {formatData(parseFloat(topTurnover), "currency")}
                  </div>
                  <div className={styles.goEcommerce} onClick={goEcommerce}>
                    Ir a comprar
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.containerProductCardPOS} key={index}>
                <div className={styles.textPos}>
                  Solicítalo y accede a información personalizada de cientos de
                  productos.
                </div>
              </div>
            )}
          </animated.div>
        )}
      </Transition>
    </div>
  );
}

export default ProductCardMobile;
