import React from "react";
import moment from "moment";
import "moment/locale/es";
import formatData from "../../../../../utils/formatData";

//Styles
import styles from "./historyPriceMobile.module.css";

moment.locale("es");

function HistoryPriceMobile(props) {
  const { storesWithReferences, quantity, average, cityId } = props;

  return (
    <div className={styles.container}>
      <div className={styles.containerHistoryPrice}>
        Durante {moment.utc().format("MMMM")} del {moment.utc().format("YYYY")}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.average}>
          <div className={styles.textAverage}>Promedio mensual de Ventas:</div>
          <div className={styles.totalAverage}>
            {!!average
              ? formatData(parseFloat(average), "currency", cityId)
              : "N/A"}
          </div>
        </div>
        <div className={styles.infoMySales}>
          <div className={styles.containerTextInfoMySales}>
            Unidades vendidas por negocio:
          </div>
          <div className={styles.totalMonth}>
            {!!quantity ? quantity : "N/A"}
          </div>
        </div>
        <div className={styles.countReference}>
          <div className={styles.containerTextInfo}>
            Negocios con esta referencia:
          </div>
          <div className={styles.total}>
            {!!storesWithReferences ? storesWithReferences : "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryPriceMobile;
