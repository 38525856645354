import axios from "axios";

const URL_API_POS = process.env.REACT_APP_URL_POS_API;

var CancelToken = axios.CancelToken;
var call1 = CancelToken.source();

const getHeaders = () => {
  let headers = {};
  headers = {
    cancelToken: call1.token,
    // headers: { Authorization: `Bearer ${process.env.TOKEN}` },
  };
  return headers;
};

export const getPOS = async (path, params) => {
  const headers = getHeaders();
  var resolveReturn, rejectReturn;
  const promise = new Promise((resolve, reject) => {
    resolveReturn = resolve;
    rejectReturn = reject;
  });

  try {
    const resp = await axios.get(`${URL_API_POS}${path}`, {
      ...headers,
      cancelToken: params && params.cancelToken,
    });
    resolveReturn(resp);
  } catch (error) {
    rejectReturn(error);
  }
  return promise;
};

export const postPOS = async (path, params) => {
  const headers = getHeaders();
  var resolveReturn, rejectReturn;
  const promise = new Promise((resolve, reject) => {
    resolveReturn = resolve;
    rejectReturn = reject;
  });

  try {
    const resp = await axios.post(`${URL_API_POS}${path}`, params, headers);
    resolveReturn(resp);
  } catch (error) {
    rejectReturn(error);
  }
  return promise;
};
