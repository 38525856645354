//eslint-disable-next-line
import React, { useState } from "react";
import styles from "./selectCityMobile.module.css";
import triangle from "../../../../../../assets/images/desktop/Triangle.svg";

const SelectCityMobile = (props) => {
  const { selectCityId } = props;
  const [checked, setChecked] = useState(false);
  const [cityId, setCityId] = useState(null);

  const change = (event) => {
    setCityId(event.target.value);
    setChecked(true);
  };

  const continues = () => {
    selectCityId(cityId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        ¿En que ciudad te encuentras ubicado?
      </div>
      <div className={styles.containerSubTitle}>
        Selecciona una para continuar.
      </div>
      <select
        className={styles.list}
        name="city"
        style={{ backgroundImage: `url(${triangle})` }}
        onChange={change}
        valuedefault={0}
      >
        <option className={styles.valueDefault} value="0">
          Selecciona
        </option>
        <option className={styles.options} value="3">
          Barranquilla
        </option>
        <option className={styles.options} value="1">
          Bogotá
        </option>
        <option className={styles.options} value="2">
          Cali
        </option>
        <option className={styles.options} value="11">
          Ciudad de México
        </option>
        <option className={styles.options} value="7">
          Medellín
        </option>
        <option className={styles.options} value="19">
          Monterrey
        </option>
      </select>
      {checked ? (
        <button
          type="button"
          onClick={continues}
          className={styles.buttonChecked}
        >
          Continuar
        </button>
      ) : (
        <button
          type="button"
          disabled
          onClick={continues}
          className={styles.buttonNoChecked}
        >
          Continuar
        </button>
      )}
    </div>
  );
};
export default SelectCityMobile;
