import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";

// Assets
import logoHeader from "./../../../../../../assets/images/desktop/chiper-pos.svg";
import logoTruck from "./../../../../../../assets/images/desktop/Truck.svg";
import logoPaper from "./../../../../../../assets/images/desktop/Paper.svg";

//Styles
import styles from "./header.module.css";

const Header = (props) => {
  const { cityId } = props;

  const goEcommerce = () => {
    if (Number(cityId) === 11 || Number(cityId) === 19) {
      window.open("https://chiper.mx/pedir/dashboard", "_blank");
    } else {
      window.open("https://chiper.co/pedir/dashboard", "_blank");
    }
  };

  const goForm = () => {
    window.open("https://growth.chiper.co/formulario-pos/", "_blank");
  };

  return (
    <Fragment>
      <header className={styles.container}>
        <div className={styles.left} onClick={goEcommerce}>
          <div className={styles.circleBlack}>
            <img
              className={styles.logoTruck}
              src={logoTruck}
              alt={"Pide aqui"}
            />
          </div>
          <div className={styles.textHere}>Pide aquí</div>
        </div>
        <img className={styles.logoHeader} src={logoHeader} alt={"Chiper"} />
        <div className={styles.right} onClick={goForm}>
          <div className={styles.circleBlack}>
            <img
              className={styles.logoPaper}
              src={logoPaper}
              alt={"Solicita tu POS"}
            />
          </div>
          <div className={styles.textPOS}>Solicita tu POS</div>
        </div>
      </header>
    </Fragment>
  );
};

export default withRouter(Header);
