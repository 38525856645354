import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esCO from "./es-Co.json";
import esMX from "./es-MX.json";

const resources = { esCO, esMX };

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
