// React
import React from "react";

// Utils
import formatData from "../../../../../utils/formatData";

// Styles
import styles from "./historyPrice.module.css";

function HistoryPrice(props) {
  const {
    average,
    cityId,
    dataHistoryPrice,
    quantity,
    storesWithReferences,
  } = props;

  return (
    <div className={styles.container}>
      {!!dataHistoryPrice && dataHistoryPrice.length > 0 && (
        <div className={styles.containerHistoryPrice}>
          <div className={styles.titleContainer}>
            <div className={styles.titleText}>Tu historial de precios</div>
          </div>
          <div className={styles.contentTableCustomers}>
            <table cellspacing="0" cellpadding="0">
              <tbody className={styles.tbodyContainer}>
                <tr className={styles.headerHistoryPrice}>
                  <th className={styles.columDates}>
                    <div className={styles.textTitleColumns}>Mes</div>
                  </th>
                  <th
                    className={styles.columCustomer}
                    style={{
                      backgroundColor: "#D1E3FA",
                    }}
                  >
                    <div className={styles.textTitleColumnsPrices}>
                      Promedio
                    </div>
                  </th>
                  <th
                    className={styles.columMargins}
                    style={{
                      backgroundColor: "#AFCEF7",
                    }}
                  >
                    <div className={styles.textTitleColumnMargin}>
                      Mayor rotación
                    </div>
                  </th>
                </tr>
                {dataHistoryPrice.map((price, index) => {
                  const key = Object.keys(price);
                  const average =
                    !!price[key].length &&
                    price[key]
                      .map((a) => parseFloat(a.price))
                      .reduce((a, b) => a + b) / price[key].length;
                  return (
                    <tr key={index}>
                      <td className={styles.columDate}>
                        <div className={styles.textColumnDate}>{key}</div>
                      </td>
                      {index % 2 !== 0 ? (
                        <td
                          className={styles.columPrice}
                          style={{ backgroundColor: "#EDF4FD" }}
                        >
                          <div className={styles.textColumnPrice}>
                            {average
                              ? formatData(
                                  parseFloat(average),
                                  "currency",
                                  cityId
                                )
                              : "N/A"}
                          </div>
                        </td>
                      ) : (
                        <td
                          className={styles.columPrice}
                          style={{ backgroundColor: "#E6EDF7" }}
                        >
                          <div className={styles.textColumnPrice}>
                            {average
                              ? formatData(
                                  parseFloat(average),
                                  "currency",
                                  cityId
                                )
                              : "N/A"}
                          </div>
                        </td>
                      )}
                      {index % 2 !== 0 ? (
                        <td
                          className={styles.columMargin}
                          style={{ backgroundColor: "#F6F9FE" }}
                        >
                          <div className={styles.textColumnMargin}>
                            {!!price[key].length
                              ? formatData(
                                  parseFloat(price[key][0].price),
                                  "currency",
                                  cityId
                                )
                              : "N/A"}
                          </div>
                        </td>
                      ) : (
                        <td
                          className={styles.columMargin}
                          style={{ backgroundColor: "#EFF3F7" }}
                        >
                          <div className={styles.textColumnMargin}>
                            {!!price[key].length
                              ? formatData(
                                  parseFloat(price[key][0].price),
                                  "currency",
                                  cityId
                                )
                              : "N/A"}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className={styles.infoMySales}>
        <div className={styles.countReference}>
          <div className={styles.containerTextInfoMySales}>
            <div className={styles.textSupMonth}>Promedio de unidades</div>
            <div className={styles.textSubMonth}>vendidas por negocio:</div>
          </div>
          <div className={styles.totalMonth}>
            {!!quantity ? quantity : "N/A"}
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.countReference}>
          <div className={styles.containerTextInfo}>
            <div className={styles.textSup}>Negocios con</div>
            <div className={styles.textSub}>esta referencia:</div>
          </div>
          <div className={styles.total}>
            {!!storesWithReferences ? storesWithReferences : "N/A"}
          </div>
        </div>
        <div className={styles.average}>
          <div className={styles.containerAverage}>
            <div className={styles.textSupAverage}>Promedio mensual</div>
            <div className={styles.textSubAverage}>de Ventas:</div>
          </div>
          <div className={styles.totalAverage}>
            {average
              ? formatData(parseFloat(average), "currency", cityId)
              : "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryPrice;
