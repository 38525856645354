import React from "react";
import buttonLeft from "../../../../../../assets/images/desktop/buttonLeft.svg";
import buttonRight from "../../../../../../assets/images/desktop/buttonRight.svg";
import buttonRightDisabled from "../../../../../../assets/images/desktop/buttonRightDisabled.svg";
import formatData from "../../../../../utils/formatData";

//Styles
import styles from "./productCard.module.css";

function ProductCard(props) {
  const {
    cityId,
    dataReference,
    dataGeneralLength,
    decreaseSku,
    increaseSku,
    index,
    topTurnover,
  } = props;

  const goForm = () => {
    window.open("https://growth.chiper.co/formulario-pos/", "_blank");
  };

  return (
    <div className={styles.containerProductCard}>
      <div className={styles.titleContainer}>
        {index === dataGeneralLength ? (
          <div className={styles.titleText}>Chiper POS</div>
        ) : (
          <div className={styles.titleText}>Los más vendidos</div>
        )}
      </div>
      <div className={styles.containerImageCard}>
        <div className={styles.referenceTitle}>
          {!!dataReference &&
          dataReference.name &&
          dataReference.name.length > 35
            ? dataReference.name.slice(0, 35) + "..."
            : dataReference.name}
        </div>
        <div className={styles.containerImage}>
          {index > 0 ? (
            <button
              className={styles.leftBottom}
              onClick={() => decreaseSku(index)}
            >
              <img
                src={buttonLeft}
                alt={buttonLeft}
                className={styles.leftBottomImage}
              ></img>
            </button>
          ) : (
            <button className={styles.leftBottomDisabled} disabled>
              <img
                src={buttonRightDisabled}
                alt={buttonRightDisabled}
                className={styles.leftBottomImageDisabled}
              ></img>
            </button>
          )}
          <img
            src={
              dataReference.imageUrl
                ? dataReference.imageUrl
                : "https://d221xxk5mfaxk5.cloudfront.net/app/image-not-found-HJDRw_QZI-M.png"
            }
            alt={dataReference.name}
            className={styles.image}
          ></img>
          {index < dataGeneralLength ? (
            <button
              className={styles.rightBottom}
              onClick={() => increaseSku(index)}
            >
              <img
                src={buttonRight}
                alt={buttonRight}
                className={styles.rightBottomImage}
              ></img>
            </button>
          ) : (
            <button disabled className={styles.rightBottomDisabled}>
              <img
                src={buttonRightDisabled}
                alt={buttonRightDisabled}
                className={styles.rightBottomImageDisabled}
              ></img>
            </button>
          )}
        </div>
      </div>
      {topTurnover ? (
        <div className={styles.containerPriceTopTurnover}>
          <div className={styles.supText}>Precio de mayor rotación:</div>
          <div className={styles.inputTopTurnoverPrice}>
            {formatData(parseFloat(topTurnover), "currency", cityId)}
          </div>
        </div>
      ) : (
        <div className={styles.descriptionContainer}>
          <div className={styles.description}>
            Solicitalo y accede a información personalizada de cientos de
            productos.
          </div>
          <button className={styles.goForm} onClick={goForm}>
            Solicitalo ahora, es gratis
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductCard;
