import React from "react";
// Icons
import lupaBlack from "../../../../../../assets/images/desktop/lupaBlack.svg";
//Styles
import styles from "./searchbar.module.css";

function SearchBar() {
  return (
    <div>
      <div className={styles.searchBar}>
        <img src={lupaBlack} alt={lupaBlack} className={styles.lupa}></img>
        <input
          disabled
          autocomplete="off"
          id="nameProvider"
          className={styles.inputText}
          placeholder="Adquiere tu POS para activar el buscador y consultar cualquier producto."
        ></input>
      </div>
    </div>
  );
}

export default SearchBar;
