import React from "react";
import Home from "../home";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/pos" component={Home}></Route>
        <Redirect from="/*" to="/" />
      </Switch>
    </Router>
  );
};

export default Navigation;
